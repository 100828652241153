import React from 'react'
import PriceList from '../components/PriceList/price-list'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const App = ({data, pageContext}) => {
  let title = pageContext?.category
  return(
    <Layout>
    <PriceList data={data} title={title} context={pageContext} />
    </Layout>
  )
}

export default App

export const query = graphql`
query($category: String!){
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/data/products/"}, frontmatter: {product_category: {eq: $category}, hide_from_price_list: {ne: true}}}, sort: {order: ASC, fields: [frontmatter___product_name]}) {
    nodes {
      frontmatter {
        product_name
        configurator_id
        smart_code
        product_category
        sort_name
        pricing_options {
          description
          model
          weight
          weight_options
          unit_text
          modified_standard
          hide_from_price_list
          base_configuration
          net_price {
            currency
            value
            pricing_schedule
          }
        }
        hardware_kits {
          hardware_kit
          description
          default_quantity
          smart_code_restriction {
            position
            smart_code
          }
        }
      }
    }
  }
}
`
