module.exports = {
  renderStyle: {
    width: 800,
    height: 'calc(100% - 50px)',
    marginTop: 50,
    marginLeft: 'calc( (100vw / 2) - 400px)'
  },
  bodyStyle: {
    paddingTop: 100,
    paddingBottom: 75,
    paddingLeft: 27,
    paddingRight: 27,
    fontFamily: 'Helvetica Neue',
    fontWeight: 100
  },
  headerStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 556,
    height: 80
  },
  priceListTitle: {
    textAlign: 'right',
    width: 556,
    fontSize: 12.3,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 900,
    marginLeft: 27,
    marginTop: 5,
    marginRight: 0,
  },
  internalUseTitle: {
    textAlign: 'right',
    width: 556,
    marginLeft: 27,
    marginTop: 33,
    fontSize: 9,
    fontFamily: 'Helvetica Neue Bold'
  },
  headerLineStyle: {
    width: 556,
    height: 2,
    position: 'absolute',
    left: 27,
    top: 68
  },
  productTitle: {
    fontSize: 9,
    fontFamily: 'Helvetica Neue Bold',
    textTransform: 'uppercase',
    marginBottom: 1,
    paddingBottom: 0
  },
  productTitleSpan: {
    fontSize: 9,
    fontFamily: 'Helvetica Neue Bold',
    textTransform: 'none',
    marginBottom: 1,
    paddingBottom: 0
  },
  tableWrapper: {
    display: "flex"
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    marginTop: 0,
    marginBottom: 15,
    borderWidth: .5, borderRightWidth: 0, borderBottomWidth: 0 },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableColSmall: { width: "16%", borderStyle: "solid", borderWidth: .5, borderLeftWidth: 0, borderTopWidth: 0, padding: 3, paddingBottom: 0 },
  tableColSmallFilled: { width: "16%", backgroundColor: "#f0f0f0", borderStyle: "solid", borderWidth: .5, borderLeftWidth: 0, borderTopWidth: 0, padding: 3, paddingBottom: 0 },
  tableColLarge: { width: "52%", borderStyle: "solid", borderWidth: .5, borderLeftWidth: 0, borderTopWidth: 0, padding: 3, paddingBottom: 0 },
  tableCellHeader: { fontFamily: 'Helvetica Neue Bold', fontSize: 6, lineHeight: 1.25, textAlign: 'left', textTransform: 'uppercase', fontWeight: 900, padding: 0, margin: 0 },
  tableCell: { fontSize: 7, lineHeight: 1.25, textAlign: 'left', padding: 0, margin: 0 },
  tableCellRight: { fontSize: 7, textAlign: 'right', padding: 0, margin: 0 },
  footerStyle: {
    width: 555,
    height: 60,
    position: 'absolute',
    left: 27,
    bottom: 10
  },
  footerTextStyle: {
    fontSize:7,
    fontWeight: 'bold'
  },
  footerLineStyle: {
    width: 555,
    height: 2,
    position: 'absolute',
    left: 0,
    bottom: 48
  },
  copyRight: {
    fontSize: 5,
    fontWeight: 'normal',
    marginTop: 5
  },
  pageRevisions: {
    fontSize: 7,
    position: 'absolute',
    right: 0,
    bottom: 20
  },
  pricingDisclaimer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    marginTop: 7,
    fontSize: 8,
    fontWeight: 200,
    width: 110,
  }
}
