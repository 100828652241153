import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import styles from './price-list-styles'
import {
  mergeHardwareKitsIntoPricing
} from '../../utils/pricing'
class PriceTable extends React.Component {

  render(){
    let maxRows = 35
    this.props.product.frontmatter = mergeHardwareKitsIntoPricing(this.props.product.frontmatter, this.props.hardwareKits)
    let standardPricing = this.props.product.frontmatter.pricing_options.filter((option) => {
      return !option.modified_standard && !option.hide_from_price_list
    })
    let modifiedStandard = this.props.product.frontmatter.pricing_options.filter((option) => {
      return option.modified_standard && !option.hide_from_price_list
    })
    let pricingOptions = [
      { options: standardPricing },
      { options: modifiedStandard, modifiedStandard: true }
    ]
    if(standardPricing.length > maxRows) {
      let tempOptions = standardPricing
      pricingOptions =[]
      while(tempOptions.length) {
        pricingOptions.push({options: tempOptions.splice(0,maxRows)})
      }
      pricingOptions.push({ options: modifiedStandard, modifiedStandard: true })
    }
    let priceTables = pricingOptions.map((pricingOption, i) => {
      let options = null
      options = pricingOption.options.map((option, j) => {
        // let net_price = option.net_price.find((object)=> {
        //   return object.currency === "USD" && object.pricing_schedule != 'data/pricing-schedules/2022-pricing-schedule.md'
        // })
        let net_price = option?.net_price[0]
        let net_price_val = net_price?.value
        if(!net_price_val){
          net_price_val = 'please call for pricing'
        }else{
          if(option.default_quantity){
            net_price_val *= option.default_quantity
          }
          net_price_val = '$'+net_price_val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        if(option.unit_text){
          net_price_val += ' ' + option.unit_text
        }
        let weight = option.weight
        if(parseInt(weight) > 0){
          weight = weight + ' lbs ('+parseInt(weight*0.453592)+' kg)'
        }else{
          weight = ''
        }
        let weightColStyle = styles.tableColSmall
        let modelColStyle = styles.tableColSmall
        let model = option.model
        if(!model){
          modelColStyle = styles.tableColSmallFilled
        }
        if(!weight){
          weightColStyle = styles.tableColSmallFilled
        }
        return (
          <View style={styles.tableRow} key={j}>
            <View style={modelColStyle}>
              <Text style={styles.tableCell}>{option.model}</Text>
            </View>
            <View style={styles.tableColLarge}>
              <Text style={styles.tableCell}>{option.description}</Text>
            </View>
            <View style={styles.tableColSmall}>
              <Text style={styles.tableCellRight}>{net_price_val}</Text>
            </View>
            <View style={weightColStyle}>
              <Text style={styles.tableCellRight}>{weight}</Text>
            </View>
          </View>
        )
      })
      if(options.length){
        let productTitle = <>{this.props.product.frontmatter.product_name} - NET PRICING AND ORDERING INFORMATION <Text style={styles.productTitleSpan}>(pricing does not include freight)</Text></>
        let headerFields = <View style={styles.tableRow} key={-1}>
            <View style={styles.tableColSmall}>
              <Text style={styles.tableCellHeader}>MODEL</Text>
            </View>
            <View style={styles.tableColLarge}>
              <Text style={styles.tableCellHeader}>DESCRIPTION</Text>
            </View>
            <View style={styles.tableColSmall}>
              <Text style={styles.tableCellHeader}>NET PRICE (US $)</Text>
            </View>
            <View style={styles.tableColSmall}>
              <Text style={styles.tableCellHeader}>WEIGHT</Text>
            </View>
          </View>
        if(pricingOption.modifiedStandard){
          productTitle = <>{this.props.product.frontmatter.product_name} - MODIFIED STANDARD OPTIONS</>
          headerFields = null
        }
        let breakPage = null
        if(i === 0 && standardPricing.length > maxRows){
          breakPage =  <View break />
        }
        if(i === 0 && this.props.product.frontmatter.product_name.toUpperCase() === 'VECTOR SEATING SYSTEM'){
          breakPage =  <View break />
        }
        return (
          <>
          {breakPage}
          <View style={styles.tableWrapper} wrap={false} key={i}>
            <Text style={styles.productTitle}>{productTitle}</Text>
            <View style={styles.table}>
              {headerFields}
              {options}
            </View>
          </View>
          </>
        )
      }else{
        return(null)
      }

    })

    return(
      <>
      {priceTables}
      </>
    )
  }

}

export default PriceTable
